<template>
	<article class="about">
		<h1 class="o-heading about__heading">About Family Dinner</h1>
		<section class="about__content">
			<p class="o-copy">
				Family Dinner is the new location of the Basar family "social network". Features will be released from time to time (such as commenting, reactions, etc.). Family Dinner is split up into "rooms" to simulate going to a family dinner and having conversations. Feel free to bounce around to the rooms to get a feel for them. More rooms will be added from time to time.
			</p>
			<p class="o-copy">
				This is a place for us to share things about the family and connect without all of the noise of all other social networks. For this reason <em><strong>all</strong> devisive, political, and argumentative posts will be immediately removed</em>. If you feel the need to post this type of thing, you can head over to <a href="https://facebook.com">the room specifically for this</a>. If you're unable to follow this simple rule, your account is subject to being deactivated and/or deleted.
			</p>
		</section>
		<section class="about__actions">
			<BaseButton @click="goToRooms">Go To Rooms</BaseButton>
		</section>
	</article>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BaseButton from '@/components/BaseButton.vue';
import router from '@/router';

export default defineComponent({
  components: {
    BaseButton,
  },

  setup() {
    function goToRooms(): void {
      router.push('the-foyer');
    }

    return {
      goToRooms,
    }
  }
})
</script>

<style lang="scss" scoped>
.about {
  max-width: 700px;
  margin: 0 auto;
  &__actions {
    margin-top: 5rem;
  }
}
</style>