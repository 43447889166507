
import { defineComponent } from 'vue';
import BaseButton from '@/components/BaseButton.vue';
import router from '@/router';

export default defineComponent({
  components: {
    BaseButton,
  },

  setup() {
    function goToRooms(): void {
      router.push('the-foyer');
    }

    return {
      goToRooms,
    }
  }
})
